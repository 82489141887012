import React from 'react'
import Layout from '../../../components/Layout'
import AnimatonRoll from '../../../components/AnimationRoll'

const AnimationProjectPage = () => (
  <Layout>
    <div
      className="full-width-image-container margin-top-0"
      style={{
          backgroundImage: `linear-gradient(-90deg, #34e694, #6e8fec)`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          color: 'white',
          padding: '1rem',
        }}
      >
        Animation challenge
      </h1>
    </div>
    <section className="section">
      <div className="container">
        <div className="content">
          <div class="tile">
            <h1>About challenge<br/>チャレンジにあたって</h1>
          </div>
          <p>WEB上のアニメーションの知識とスキルがないのでスキルアップするための取り組み
          </p>
          <ul>
            <li>まずは知識を仕入れる</li>
            <li>マイクロアニメーションが出来れば業務に役立つ気がするので、ひとまずはそのあたりをターゲットにする予定</li>
            <li>フレームワークもあれば利用してみる</li>
          </ul>
          <AnimatonRoll></AnimatonRoll>
        </div>
      </div>
    </section>
  </Layout>
)

export default AnimationProjectPage